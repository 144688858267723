<template>
    <section class="step1">
        <div class="column-left">
            <div class="title-lg">個人資料</div>
            <div class="gender-selector">
                <label class="custom-radio-container gender-radio" data-v-13f4e584="" v-for="dict in genderOptions">
                    <input type="radio" class="custom-radio-input" v-model="form.gender" :value="dict.dictValue" v-on:click="gender(dict.dictValue)">
                    <div class="custom-radio-box">
                    </div>
                    {{dict.dictLabel}}
                </label>
            </div>
            <custom-input
                v-model="form.name"
                placeholder="請輸入身份證上的姓名"
            ></custom-input>
            <input type="number" v-model="form.age" class="custom-input" oninput="value=value.replace(/[^\d]/g,'')" placeholder="年齡" min="0" max="100" @change="changeText('age')">
            <input type="text" v-model="form.email" class="custom-input"  placeholder="請輸入電郵" >
            <input type="text" v-model="form.mobile_phone" class="custom-input"  placeholder="請輸入電話號碼" >
            <div class="title-lg">你爲誰籌款</div>
            <div class="gender-selector">
                <label class="custom-radio-container gender-radio" data-v-13f4e584="" v-for="dict in genderOptions">
                    <input type="radio" class="custom-radio-input" v-model="form.to_gender" :value="dict.dictValue" v-on:click="to_gender(dict.dictValue)">
                    <div class="custom-radio-box">
                    </div>
                    {{dict.dictLabel}}
                </label>
            </div>
            <custom-selection
                        v-model="form.to_relation_type"
                        :options="toRelationTypeOptions"
                    ></custom-selection>
            <custom-input
                v-model="form.patient_name"
                placeholder="患者姓名"
            ></custom-input>
            <custom-input
                v-model="form.patient_disease"
                placeholder="患者所患疾病"
            ></custom-input>
        </div>
        <div class="column-right">
            <div class="title-lg">申請資料</div>
            <custom-input
                v-model="form.reason_application"
                placeholder="申請原因"
            ></custom-input>
            <custom-textarea
                v-model="form.explain_detail"
                rows="3"
                placeholder="詳細解釋"
            ></custom-textarea>
            <div class="upload-container">
                <div class="description">
                    <div class="title">請上傳病歷相關證明文件</div>
                    <div class="notice">*文件的完整度會直接影響審批速度</div>
                    <div class="notes">每個檔案不能超過20MB</div>
                </div>
                <el-form-item>
                    <el-upload
                    class="upload-demo"
                    multiple
                    action="#"
                    :limit="5"
                    accept="image/png, image/jpeg, image/jpg, application/pdf"
                    :on-remove="(file, fileList)=>{return otherHandleRemove(file, fileList)}"
                    :on-exceed="handleExceed"
                    :http-request="requestUpload"
                    :before-upload="beforeAvatarUpload"
                    :before-remove="beforeRemove"
                    :file-list="form.related_medical_files">
                    <el-button size="small" type="primary">點擊上傳</el-button>
                    </el-upload>
                </el-form-item>
                <!-- <custom-file-upload></custom-file-upload>
                <div class="file-list">
                    <div v-for="(item,index) in form.related_medical_files" :key="index">{{item.name}}&nbsp;&nbsp;<span @click="delete_related_medical_files({index})" style="color:red;">x</span></div>
                </div> -->
            </div>
            <div class="upload-container">
                <div class="description">
                    <div class="title">
                        請上傳生活照、現況、經濟情況相關證明文件
                    </div>
                    <div class="notice">*文件的完整度會直接影響審批速度</div>
                    <div class="notes">每個檔案不能超過20MB</div>
                </div>
                 <el-form-item>
                    <el-upload
                    class="upload-demo"
                    multiple
                    action="#"
                    :limit="5"
                    :on-remove="(file, fileList)=>{return otherHandleRemove2(file, fileList)}"
                    :on-exceed="handleExceed2"
                    :http-request="requestUpload2"
                    :before-upload="beforeAvatarUpload2"
                    accept="image/png, image/jpeg, image/jpg, application/pdf"
                    :before-remove="beforeRemove2"
                    :file-list="form.other_files">
                    <el-button size="small" type="primary">點擊上傳</el-button>
                    </el-upload>
                </el-form-item>
                <!-- <custom-file-uploads></custom-file-uploads>
                <div class="file-list">
                    <div v-for="(item,index) in form.other_files" :key="index">{{item.name}}&nbsp;&nbsp;<span @click="delete_other_files({index})" style="color:red;">x</span></div>
                </div> -->
            </div>
            <primary-button radius="none" style="margin-top:90px" class="next-button" @click="validate">
                下一步
            </primary-button>
        </div>
        <div id="app">
			<div class="toast" v-show="toastShow">
				{{toastText}}
			</div>
		</div>
    </section>
</template>


<script>
import { mapMutations } from "vuex";
import api from "@/api";
export default {
    data() {
        return {
            toastShow: false,
            toastText: '',
            // 状态数据字典
            genderOptions: [],
            toRelationTypeOptions: [
                { label: '是否本人', value: '' }
            ],
            form: {
                // 性别
                gender: "",
                 // 請輸入身份證上的姓名
                name:"", 
                // 年龄
                age: '',
                // 邮件
                email: "",
                // 電話號碼
                mobile_phone: "",
                // 患者性别
                to_gender:"",
                // 患者是否是本人
                to_relation_type:"",
                // 患者名字
                patient_name:'',
                //患者所患疾病
                patient_disease:"",
                // 申請原因
                reason_application:"",
                // 詳細解釋
                explain_detail:"",
                // 請上傳生活照、現況、經濟情況相關證明文件
                other_files:[],
                // 請上傳病歷相關證明文件
                related_medical_files:[],
            },
        };
    },
    computed:{
        related_medical_file(){
            return this.$store.state.personal.related_medical_files;
        },
        other_file(){
            return this.$store.state.personal.other_files;
        }
    },
    watch:{
        'form.to_relation_type':{
            handler: function() {
                // 儅選擇是本人時，將個人資料下的name和性別自動填充
                if(this.form.to_relation_type == 1){
                    this.form.to_gender = this.form.gender;
                    this.form.patient_name = this.form.name;
                }
            }
        },
        related_medical_file(val){
            this.form.related_medical_files = val;
        },
        other_file(val){
            this.form.other_files = val;
        }
    },
    created: function () {
        this.form.gender = this.$store.state.personal.gender
        this.form.name = this.$store.state.personal.name;
        this.form.age = this.$store.state.personal.age;
        this.form.email = this.$store.state.personal.email;
        this.form.mobile_phone = this.$store.state.personal.mobile_phone;
        this.form.to_gender = this.$store.state.personal.to_gender;
        this.form.to_relation_type = this.$store.state.personal.to_relation_type;
        this.form.patient_name = this.$store.state.personal.patient_name;
        this.form.patient_disease = this.$store.state.personal.patient_disease;
        this.form.reason_application = this.$store.state.personal.reason_application;
        this.form.explain_detail = this.$store.state.personal.explain_detail;
        this.form.other_files = this.$store.state.personal.other_files;
        this.form.related_medical_files = this.$store.state.personal.related_medical_files;
        api.dictData.getDicts("sys_user_sex").then(response => {
            this.genderOptions = response.data;
        });
        api.dictData.getDicts("sys_to_relation_type").then(response => {
            for(let i=0;i<response.data.length;i++){
                let item =response.data[i]
                this.toRelationTypeOptions.push({ label: item.dictLabel, value: item.dictValue })
            }
        });
    },
    methods: {
      requestUpload(){

      },
      requestUpload2(){

      },
      handleExceed(files, fileList) {
        this.$message.warning(`當前限制選擇 5 個文件，本次選擇了 ${files.length} 個文件，共選擇了 ${files.length + fileList.length} 個文件`);
      },
      otherHandleRemove(file, fileList) {
        let files = this.$store.state.personal.related_medical_files;
        files.forEach((item, index) => {
          if (file.name == item.name) {
            files.splice(index, 1);
          }
        });
        this.form.related_medical_files =  files;
      },
      otherHandleRemove2(file, fileList) {
        let files = this.$store.state.personal.other_files;
        files.forEach((item, index) => {
          if (file.name == item.name) {
            files.splice(index, 1);
          }
        });
        this.form.other_files =  files;
      },
      beforeRemove(file, fileList) {
        return this.$confirm(`確定移除 ${ file.name }？`);
      },
      beforeAvatarUpload(file) {
        let types = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];
        let isUploadFile = types.includes(file.type);
        let fileSizeFlag = file.size / 1024 / 1024  < 20 ? true : false;
        if (! isUploadFile) {
          this.$message.error('上傳文件只能是image、pdf格式');
          return false;
        }
        if(! fileSizeFlag) {
          this.$message({message: '上傳文件大小不能超過20m',type: 'error'});
          return false;
        }else{
         this.loading = true;
          let formData = new FormData();
          formData.append("file", file);
          formData.append("source", '籌款上傳');
          let that = this;
                 that.$axios({
                    url:process.env.VUE_APP_API_SERVER+'/front/savefile/',
                    method: 'post',
                    data:formData
                }).then(function(res){
                    let store_files = that.$store.state.personal.related_medical_files;
                    if(store_files.length === 0){
                        that.$store.state.personal.related_medical_files = [{
                            'file':res.data.data.file_url,
                            'name':res.data.data.name
                        }];
                    }else{
                        let a = [];
                        for(let i=0; i<store_files.length; i++){
                             a[i] = {
                                'file':store_files[i]['file'],
                                'name':store_files[i]['name']
                            };
                        }
                        a.push({
                            'file':res.data.data.file_url,
                            'name':res.data.data.name
                        });
                        that.$store.state.personal.related_medical_files = a;
                    }
                    that.loading = false;
                }).catch(function(err){
                    that.loading = false;
                })
            }
      },

      handleExceed2(files, fileList) {
        this.$message.warning(`當前限制選擇 5 個文件，本次選擇了 ${files.length} 個文件，共選擇了 ${files.length + fileList.length} 個文件`);
      },
      beforeRemove2(file, fileList) {
        return this.$confirm(`確定移除 ${ file.name }？`);
      },
      beforeAvatarUpload2(file) {
        let types = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];
        let isUploadFile = types.includes(file.type);
        let fileSizeFlag = file.size / 1024 / 1024  < 20 ? true : false;
        if (! isUploadFile) {
          this.$message.error('上傳文件只能是image、pdf格式');
          return false;
        }
        if(! fileSizeFlag) {
          this.$message({message: '上傳文件大小不能超過20m',type: 'error'});
          return false;
        }else{
         this.loading = true;
          let formData = new FormData();
          formData.append("file", file);
          formData.append("source", '籌款上傳');
          let that = this;
                 that.$axios({
                    url:process.env.VUE_APP_API_SERVER+'/front/savefile/',
                    method: 'post',
                    data:formData
                }).then(function(res){
                   let store_files = that.$store.state.personal.other_files;
                    if(store_files.length === 0){
                        that.$store.state.personal.other_files = [{
                            'file':res.data.data.file_url,
                            'name':res.data.data.name
                        }];
                    }else{
                        let a = [];
                        for(let i=0; i<store_files.length; i++){
                            a[i] = {
                                'file':store_files[i]['file'],
                                'name':store_files[i]['name']
                            };
                        }
                        a.push({
                            'file':res.data.data.file_url,
                            'name':res.data.data.name,
                        });
                        that.$store.state.personal.other_files = a;
                    }
                    that.loading = false;
                }).catch(function(err){
                    that.loading = false;
                })
                // this.$refs.uploader.value = null;
            }
      },

        delete_related_medical_files(index){
            this.$store.state.personal.related_medical_files.splice(index,1);
        },
        delete_other_files(index){
            this.$store.state.personal.other_files.splice(index,1);
        },
        toast(str) {
            let v = this
            v.toastText = str
            v.toastShow = true
            setTimeout(function() {
                v.toastShow = false
            }, 1500)
        },
        gender(val){
            this.$store.state.personal.gender = val;
        },
        to_gender(val){
            this.$store.state.personal.to_gender = val;
        },
        identity_name(){
            let name = this.form.name;
            
            let reg = new RegExp('[:;/|,*?"><]')
            let isInclude = name.match(reg)
            if (isInclude || name.indexOf('\\') !== -1) {
                this.$message.error('身份證姓名不支持特殊字符')
                return 'error';
            }

            if(name.length < 2 || name.length > 50){
                this.$message.error('身份證姓名長度在 2 到 50 個字符')
                return 'error';
            }
        },
        age(){
            let age = this.form.age;
            if(age === 0){
                this.$message.error('年齡範圍在1-120')
                return 'error';
            }
        },
        patient_disease(){
            let disease = this.form.patient_disease;
            if(disease.length < 2 || disease.length > 50){
                this.$message.error('患者所患疾病長度在 2 到 50 個字符')
                return 'error';
            }
        },
        explain_detail(){
            let explain_detail = this.form.explain_detail;
            if(explain_detail.length < 2){
                this.$message.error('詳細解釋不能少於 2 個字符')
                return 'error';
            }
        },
        reason_application(){
            let reason_application = this.form.reason_application;
            if(reason_application.length < 2  || reason_application.length > 120){
                this.$message.error('申請原因長度在 2 到 120 個字符')
                return 'error';
            }
        },
        patient_name(){
            let name = this.form.patient_name;
            
            let reg = new RegExp('[:;/|,*?"><]')
            let isInclude = name.match(reg)
            if (isInclude || name.indexOf('\\') !== -1) {
                this.$message.error('患者姓名不支持特殊字符')
                return 'error';
            }

            if(name.length < 2 || name.length > 50){
                this.$message.error('患者姓名長度在 2 到 50 個字符')
                return 'error';
            }
        },
        changeText(val){
            if(val == 'age'){
                if(this.form.age > 120){
                    this.form.age = 120;
                }
            }
        },
        email_blur() {
            var verify = /^\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/;
            if (!verify.test(this.form.email)) {
                this.$message.error('郵箱格式錯誤,請重新輸入')
                return 'error';
            }
        },
        phone(){
            if(!(/^[1][3-9]\d{9}$|^(852)\d{8}$|^(853)\d{8}$/.test(this.form.mobile_phone))){
                this.$message.error('電話號碼格式錯誤,請重新輸入')
                return 'error';
            }
        },
        ...mapMutations({
            next: "newDonation/next",
        }),
        validate() {
            if((this.identity_name() || this.age() || this.patient_disease() || this.explain_detail() || this.reason_application() || this.patient_name() ||  this.email_blur() || this.phone()
            ) == 'error'){
                return '';
            }
            if(
                (this.form.gender || this.form.gender === 0) 
            && this.form.name 
            && (this.form.age || this.form.age === 0) 
            && this.form.email 
            && this.form.mobile_phone
            && (this.form.to_gender || this.form.to_gender === 0)
            && (this.form.to_relation_type || this.form.to_relation_type === 0) 
            && this.form.patient_name
            && this.form.patient_disease
            && this.form.reason_application 
            && this.form.explain_detail){
                
                if(this.form.related_medical_files.length === 0){
                    this.$message.error('請上傳病歷相關證明文件');
                    return '';
                }
                
                if(this.form.other_files.length === 0){
                    this.$message.error('請上傳生活照、現況、經濟情況相關證明文件');
                    return '';
                }

                if(this.identity_name() == 'error'){
                    return '';
                }
                if(this.age() === 'error'){
                    return '';
                }
                if(this.email_blur() == 'error'){
                    return '';
                }
                if(this.phone() == 'error'){
                    return '';
                };
                if(this.patient_name() == 'error'){
                    return '';
                }
                if(this.patient_disease() == 'error'){
                    return '';
                }
                if(this.reason_application() == 'error'){
                    return '';
                }
                if(this.explain_detail() == 'error'){
                    return '';
                }
                this.$store.state.personal.gender = this.form.gender;
                this.$store.state.personal.name = this.form.name;
                this.$store.state.personal.age = this.form.age;
                this.$store.state.personal.email = this.form.email;
                this.$store.state.personal.mobile_phone = this.form.mobile_phone;
                this.$store.state.personal.patient_disease = this.form.patient_disease;
                this.$store.state.personal.reason_application = this.form.reason_application;
                this.$store.state.personal.to_gender = this.form.to_gender;
                this.$store.state.personal.patient_name = this.form.patient_name;
                this.$store.state.personal.explain_detail = this.form.explain_detail;
                this.$store.state.personal.to_relation_type = this.form.to_relation_type;
                this.$store.state.personal.other_files = this.form.other_files;
                this.$store.state.personal.related_medical_files = this.form.related_medical_files;
            }else{
                this.$message.error('請完善信息')
                return '';
            }
            this.next();
            this.$router.push({ name: "newDonationStep2" });
        },
        getApplyId() {
            return this.$store.state.network;
        }
    },
};
</script>

<style lang="scss" scoped>
@import "./newDonation.scss";

.step1 {
    padding: 20px 30px;
}
.gender-selector {
    @include fr-s-c;
    .gender-radio {
        margin-right: 8px;
        &:deep(*) {
            color: $gray-500;
        }
    }
}
span{
    cursor:pointer;
}
.upload-container {
    margin: 16px 0;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    column-gap: 8px;
    width: 100%;
    align-items: center;
    .title {
        color: $gray-500;
        font-size: 16px;
        line-height: 1.2;
    }
    .notice {
        color: $red-500;
        font-size: 14px;
        line-height: 1.2;
    }
    .notes {
        color: $gray-500;
        font-size: 14px;
        line-height: 1.2;
    }
    .file-list {
        align-self: flex-start;
    }
}
.toast {
			position: fixed;
			z-index: 2000;
			left: 50%;
			top: 45%;
			transition: all .5s;
			-webkit-transform: translateX(-50%) translateY(-50%);
			-moz-transform: translateX(-50%) translateY(-50%);
			-ms-transform: translateX(-50%) translateY(-50%);
			-o-transform: translateX(-50%) translateY(-50%);
			transform: translateX(-50%) translateY(-50%);
			text-align: center;
			border-radius: 5px;
			color: #FFF;
			background: rgba(17, 17, 17, 0.7);
			height: 45px;
			line-height: 45px;
			padding: 0 15px;
			max-width: 300px;
		}
</style>